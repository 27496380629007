<template>
  <div class="flex items-center">
        <!-- :style="{'width': getPropertyReviewValue(parent.id, property.id)*10+'%', background: getBg(getPropertyReviewValue(parent.id, property.id))}" -->
        <div style="width: 332px" class="relative mr-8 i-h-30">
          <div
            class="survey-progress-bg absolute border border-secondary-four rounded-sm"
          ></div>
          <div
            class="tracker-bg absolute bg-secondary-three border border-secondary-five i-h-10"
          ></div>
          <div
            class="absolute survey-progress-track"
            id="progress-track"
            :style="{
              width: value * 10 + '%',
              background: property.bg_color || '#2b80ad',
            }"
          ></div>
          <input
            min="0"
            max="10"
            step=".1"
            type="range"
            class="survey-range cursor-pointer bg-secondary-three border border-secondary-four absolute"
            :id="'value-track-' + property.id"
            v-model="value"
          />
        </div>
        <input
          v-limit
          v-model="value"
          min="0"
          max="10"
          class="i-h-30 i-w-50 border border-gray-300 flex items-center fs-14 fw-500 text-center mr-8"
          type="number"
        />

        <p class="fs-12 fw-500 w-56 truncate">
          {{ definitionTitle }}
        </p>
    </div>
</template>

<script setup>
import { random } from 'lodash'
import {ref, computed} from 'vue'
import useDefinition from '@/composable/useDefinition'
//eslint-disable-next-line no-undef
const props = defineProps({
  property: Object,
  definitionItems: Array
});

const { getDefinitionTitleByRange } = useDefinition()

const vLimit = {
    bind: function (el) {
        el.addEventListener('input', () => {
            if(el.value > 10) {
            el.value = 10
            }
        })
    }
}

const value = ref(random(1, 10))

const localItems = computed(() => {
    return props.items
})

const definitionTitle = computed(() => {
  if(props.definitionItems?.length) {
    return getDefinitionTitleByRange(props.definitionItems, value.value)
  }
  return ''
})
</script>

<style scoped>
.survey-range {
    top: 9px;
    height: 10px;
    width: 332px;
    outline: none;
    opacity: 0;
    -webkit-appearance: none;
}
.survey-progress-track {
    height: 10px;
    left: 9px;
    top: 10px;
}
.tracker-bg {
    top:10px;
    width: 332px;
    left: 9px;
}
.survey-progress-bg {
    width: 350px;
    height: 30px;
}
</style>
