<template>
  <div class="ml-10 mr-20">
    <div class="shadow-one bg-primary-three px-10 pb-5 rounded assess-root-box w-full">
      <div class="border-b border-secondary-five" style="height: 74px">
        <!-- button will be here by using absolute -->
      </div>
      <div class="flex justify-between items-center pb-4 i-border-b-1 border-secondary-five border-solid pt-4 w-full">
          <span class="font-poppins fw-600 text-primary-one w-full">
            {{ assessmentInfo.title }}
          </span>
          <div class="flex gap-6">
<!--            <button v-show="file" @click="clear" class="focus:outline-none rounded fs-14 font-poppins text-secondary-one bg-primary-three h-10 i-pl-30 i-pr-30 i-border-1 border-secondary-one w-56">Remove Image</button>-->
<!--            <label  v-show="!file" ref="inputFile" class="grid content-center text-center cursor-pointer focus:outline-none rounded fs-14 font-poppins text-secondary-one bg-primary-three h-10 i-pl-30 i-pr-30 i-border-1 border-secondary-one w-56">-->
<!--              <input @input="e => onChange(e)" type="file" class="hidden">-->
<!--              Add Cover Image-->
<!--            </label>-->
            <button @click="sendMessage()" class="focus:outline-none rounded fs-14 font-poppins text-secondary-one bg-primary-three h-10 i-pl-30 i-pr-30 i-border-1 border-secondary-one">Save</button>
          </div>
      </div>
      <div class="send-info-box overflow-y-auto">
        <div class="pt-6 pb-5 flex justify-between i-border-b-1 border-solid border-secondary-five">
          <div class="flex items-center">
            <h2 class="fs-14 font-poppins text-primary-one">To:</h2>
            <vue-tags-input
                v-model="email"
                :tags="emails"
                placeholder="Type email here"
                class="i-ml-35 vue-tags-width"
                @tags-changed="newEmails => emails = newEmails"
                @before-adding-tag="beforeAddEmail"
            />
          </div>
          <div class="flex justify-end">
            <select class="fs-14 font-poppins text-primary-one fw-600 pr-select-dropdown bg-transparent" v-model="user_email" @change="addUser()">
              <option v-for="(user, uIndex) in getProjectUser" :key="uIndex" :value="user.email" class="fs-14 font-roboto">{{ user.email }}</option>
            </select>
            <select class="fs-14 font-poppins text-primary-one fw-600 pr-select-dropdown i-ml-30 bg-transparent" v-model="project_id">
              <option v-for="(project, pIndex) in projects" :key="pIndex" :value="project.id" class="fs-14 font-roboto">{{ project.name }}</option>
            </select>
          </div>
        </div>
        <div class="i-border-b-1 border-solid border-secondary-five py-5 flex">
          <div class="flex items-center">
            <h2 class="fs-14 font-poppins text-primary-one">From:</h2>
            <h2 class="i-ml-30 fs-14 font-poppins text-primary-one">{{ getAuthUser ? getAuthUser.email : 'N/A' }}</h2>
          </div>
        </div>
        <div class="py-5 i-border-b-1 border-solid border-secondary-five flex">
          <div class="flex items-center">
            <h2 class="fs-14 font-poppins text-primary-one">Subject:</h2>
            <input v-model="sendingInfo.subject" class="ml-4 fs-14 font-poppins text-primary-one bg-transparent placeholder-secondary-two" placeholder="Type Subject here" />
          </div>
        </div>
<!--        <div class="relative rounded mt-5 bg-gray-100 bg-cover bg-center bg-no-repeat">-->
<!--          <img :src="`${(previewURL) ? previewURL : assessItem?.definition?.file?.file_path}`" alt="" class="block w-full">-->
<!--          <div @click="$refs.inputFile.click()" class="bg-white absolute top-4 right-4 w-8 h-8 rounded-full flex justify-center items-center shadow-one cursor-pointer ">-->
<!--            <EditIcon class="w-4 h-4" />-->
<!--          </div>-->
<!--        </div>-->
        <div class="mt-5">
          <textarea v-model="sendingInfo.message" class="fs-16 font-poppins text-primary-one bg-transparent placeholder-secondary-two w-full bld-content-area send-textarea" placeholder="Write your message here"></textarea>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueTagsInput from "@johmun/vue-tags-input";
import {mapGetters} from "vuex";
import { useFile } from '@/composable/useFile'

export default {
  name: "Send",
  props: ['assessItem'],
  components: {
    VueTagsInput,
  },
  created() {
    const self = this;
    self.$store.dispatch("assessment/getProjectAndUserOfWorkspace", self.$route.params.id);
    this.sendingInfo = {
      subject: this.assessmentInfo.subject,
      message: this.assessmentInfo.message,
    }
  },
  watch: {
    assessmentInfo: function (val) {
      if(val) {
        this.sendingInfo = {
          subject: val.subject,
          message: val.message,
        }
      }
    }
  },
  data() {
    return {
      email: '',
      emails: [],
      project_id: this.$route.params.id,
      user_email: null,
      sendingInfo: {}
    };
  },
  computed: {
    ...mapGetters({
      workingAssessment: "assessment/workingAssessment",
      assessmentInfo: "assessment/assessmentInfo",
      projects: "assessment/projects"
    }),
    getAuthUser() {
      let user = JSON.parse(localStorage.getItem('_user'));
      if(user) {
        return user;
      }
      return '';
    },
    getProjectUser() {
      this.setUser(null);
      let project = this.projects.find(project => parseInt(project.id) === parseInt(this.project_id));
      let users = [];
      if(project) {
        project.responsibles.forEach(item => {
          if(item.user_id) {
            users.push({
              email: item.user.email,
              user_id: item.user.id,
            });
          } else {
            users.push({
              email: item.email,
              user_id: null
            });
          }
        });

        if(users.length > 0) {
          this.setUser(users[0].email);
        }
        return users;
      }
      return [];
    },
  },
  methods: {
    setUser(email) {
      this.user_email = email;
    },
    getEmails() {
      return this.assessmentInfo.assignee.map(item => {
        return {
          text: item.email,
          assignee_id: item.assignee_id,
          tiClasses: ['ti-valid']
        }
      });
    },
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    beforeAddEmail(obj) {
      if(this.validateEmail(obj.tag.text)) {
        if(this.getAuthUser && this.getAuthUser.email !== obj.tag.text) {
          obj.tag.assignee_id = null;
          obj.addTag();
        }
      }
    },
    addUser() {
      let existence = this.emails.find(item => item.text === this.user_email);
      if(!existence) {
        let userId = null;
        let user = this.getProjectUser.find(item => item.email === this.user_email);
        if(user && user.user_id) {
          userId = user.user_id;
        }
        if(this.getAuthUser && this.getAuthUser.id !== userId) {
          this.emails.push({
            text: this.user_email,
            assignee_id: userId,
            tiClasses: ['ti-valid']
          });
        }
      }
    },
    sendMessage()
    {
      const self = this;
      self.$Progress.start();
      self.sendingInfo.assignee = self.emails;
      self.sendingInfo.assessment_id = self.$route.params.assessment_id;

      // this.appends(self.sendingInfo)
      // this.appendFile('image')

      self.$store.dispatch("assessment/sendMessage", self.sendingInfo).then(response => {
        if(response && response.data && response.data.data && self.$route.params.assessment_id) {
          self.$Progress.finish();
          self.$router.push({ name:'intel.assess', params: {id: self.$route.params.id} });
        }
      });
    },
  },


  setup()
  {
    const { file, previewURL, onChange, clear, appends, formData, appendFile} = useFile()

    return {
      file,
      clear,
      previewURL,
      onChange,
      appends,
      formData,
      appendFile
    }
  }
}
</script>

<style scoped>
.send-textarea {
  min-height: 480px;
}
.message-buttons {
  bottom: 50px;
  left: 40px;
  right: 40px;
}
</style>
