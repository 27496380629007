<template>
  <div class="relative">
    <slot />
    <component
      :assessItem="assessItem"
      :ref="'component_' + current"
      :is="current"
      :childids="childids"
      @toggleCurrentTab="toggleCurrentTab"
      @pushChildIds="pushChildIds"
    >
      <template #edit-btn>
        <button
          class="px-5 h-10 fs-14 border border-primary-four rounded text-primary-one"
          @click="addSender()"
        >
          Save Changes
        </button>
      </template>
    </component>
  </div>
</template>

<script>
import Configure from "./Configure";
import Preview from "./Preview";
import Send from "./Send";

export default {
  name: "RightPart",
  components: {Send, Preview, Configure},
  props: ['current', 'assessItem', 'childids'],
  methods: {
    toggleCurrentTab(value) {
      this.$emit("toggleCurrentTab", value);
    },
    updateAssessment() {
      this.$refs[`component_${this.current}`].update();
    },
    pushChildIds(obj) {
      this.$emit('pushChildIds', obj);
    },
    addSender() {
      //this.$refs[`component_${this.current}`].sendMessage();
    }
  },
  watch: {
    assessItem: function (val) {
      if(this.current === 'preview') {
        this.$refs[`component_${this.current}`].scrollToIdentifier(val.id);
      }
    }
  }
}
</script>

<style scoped>

</style>
