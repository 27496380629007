<template>
  <div class="">
    <div class="relative">
      <img src="/images/instrat_logo.svg" alt="logo" class="i-insider-logo">
      <NavToggle v-if="projectId" />
    </div>
    <div class="px-10 shadow-adjust" >
      <div class="flex items-center i-h-50 w-full rounded shadow-two bg-primary-three i-pl-15 i-pr-15 i-mt-35 cursor-pointer"
           :style="{'background': !showFeedback ? getModuleBg() : ''}"
           @click="moveToCover()">
        <h2 class="fw-600 font-poppins fs-14" :class="{'text-primary-one': showFeedback, 'text-primary-three': !showFeedback}">{{ getModuleName() }}</h2>
      </div>
    </div>

    <div class="assess-preview-l-list mt-8 overflow-y-auto">
      <div class="flex flex-col pl-10 pr-10" v-for="(l1, l1Index) in getScenarioCollection()" :class="{'mt-8': l1Index > 0}"
           :key="l1Index">
        <div class="flex items-center i-h-50 w-full rounded shadow-two bg-primary-three i-pl-15 i-pr-15 cursor-pointer"
             @click="toggleExpand(l1, true, 1, l1Index)" :style="{'background': activeCollection && activeCollection.id === l1.id ? getBg(l1): ''}">
          <h2 class="fw-600 text-primary-one font-poppins fs-14 ellipse w-full" :class="{'text-primary-three': activeCollection && activeCollection.id === l1.id}">{{ getTitle(true, 1, l1Index) }} {{ l1.title }}</h2>
        </div>
      </div>
      <div class="pl-10 pr-10" v-if="userType == 'author'">
        <router-link
          class="fs-14 fw-600 text-primary-three bg-primary-one rounded i-h-50 shadow-two flex w-full items-center justify-center mt-8"
          :to="{name: 'intel.assess', id: $route.params.id}">
          Go to Management
        </router-link>
      </div>
    </div>

    <div class="assess-preview-l-list d-none">
      <div>
        <div class="item-py assess-border">
          <div class="flex items-center assessment-left-items">
            <h2 class="fs-14 uppercase leading-4 font-roboto active-text ellipse-assess fw-500">{{ getModuleName() }}</h2>
<!--            <img src="/images/icons/assessment/chevron_down.svg" alt="image" class="chevron-down opacity-4"-->
<!--                 :class="{'rotate-180': moduleExpand, 'opacity-1': moduleExpand}">-->
          </div>
        </div>
      </div>
      <div>
        <!--level 1-->
        <div class="flex flex-col" v-for="(l1, l1Index) in getScenarioCollection()" :key="l1Index">
          <div class="item-py assess-border cursor-pointer" @click="toggleExpand(l1, true, 1, l1Index)" :class="{'selected-l-data': activeCollection && activeCollection.id === l1.id}">
            <div class="flex items-center assessment-left-items">
              <h2 class="fs-14 uppercase leading-4 font-roboto grey-text ellipse-assess" :class="{'active-text': activeCollection && activeCollection.id === l1.id}">
                {{ getTitle(true, 1, l1Index) }} {{ l1.title }}
              </h2>
<!--              <img v-if="l1.child.data.length > 0" src="/images/icons/assessment/chevron_down.svg" alt="image" class="chevron-down opacity-4"-->
<!--                   :class="{'rotate-180': l1.expanded, 'opacity-1': activeCollection && activeCollection.id === l1.id}">-->
            </div>
          </div>
          <!--level 2-->
          <div class="flex flex-col d-none" v-for="(l2, l2Index) in l1.child" :key="l2Index" v-if="l1.expanded">
            <transition-group>
              <div class="assess-ml-l2" :key="'l2'">
                <div class="item-py assess-border" @click="toggleExpand(l2, false, 2, l2Index, l1Index)">
                  <div class="flex justify-between items-center assessment-left-items cursor-pointer">
                    <h2 class="fs-14 uppercase leading-4 font-roboto grey-text ellipse-assess" :class="{'active-text': activeCollection && activeCollection.id === l2.id}">
                      {{ getTitle(false, 2, l2Index, l1Index) }} {{ l2.title }}
                    </h2>
                    <img v-if="l2.child.data.length > 0" src="/images/icons/assessment/chevron_down.svg" alt="image" class="chevron-down opacity-4"
                         :class="{'rotate-180': l2.expanded, 'opacity-1': activeCollection && activeCollection.id === l2.id}">
                  </div>
                </div>
                <!--level 3-->
                <div class="flex flex-col d-none" v-for="(l3, l3Index) in l2.child" :key="l3Index" v-if="l2.expanded">
                  <transition-group>
                    <div class="assess-ml-l3" :key="'l3'">
                      <div class="item-py assess-border" @click="toggleExpand(l3, false, 3, l3Index, l1Index, l2Index)">
                        <div class="flex justify-between items-center assessment-left-items cursor-pointer">
                          <h2 class="fs-14 uppercase leading-4 font-roboto grey-text ellipse-assess" :class="{'active-text': activeCollection && activeCollection.id === l3.id}">
                            {{ getTitle(false, 3, l3Index, l1Index, l2Index) }} {{ l3.title }}
                          </h2>
                          <img v-if="l3.child.data.length > 0" src="/images/icons/assessment/chevron_down.svg" alt="image"
                               class="chevron-down opacity-4"
                               :class="{'rotate-180': l3.expanded, 'opacity-1': activeCollection && activeCollection.id === l3.id}">
                        </div>
                      </div>
                      <!--level 4-->
                      <div class="flex flex-col d-none" v-for="(l4, l4Index) in l3.child" :key="l4Index" v-if="l3.expanded">
                        <transition-group>
                          <div class="assess-ml-l4" :key="'l4'">
                            <div class="item-py assess-border">
                              <div class="flex justify-between items-center assessment-left-items cursor-pointer">
                                <h2 class="fs-14 uppercase leading-4 font-roboto grey-text ellipse-assess" :class="{'active-text': activeCollection && activeCollection.id === l4.id}">
                                  {{ getTitle(false, 4, l4Index, l1Index, l2Index, l3Index) }} {{ l4.title }}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </transition-group>
                      </div>
                    </div>
                  </transition-group>
                </div>
              </div>
            </transition-group>
          </div>

        </div>
      </div>
    </div>

    <Nav v-model="showNavigation" @close="showNavigation=false"/>
  </div>
</template>

<script setup>
import NavToggle from '@/components/navigation/components/NavToggle.vue'
import useNavigation from '@/components/navigation/useNavigation.js'
import Nav from '@/components/navigation/Nav.vue'

const { projectId, showNavigation } = useNavigation()
</script>


<script>
import {mapGetters} from "vuex";

export default {
  name: "LeftPart",
  props: ['showFeedback', 'userType'],
  data() {
    return {
      activeCollection: null,
      moduleExpand: true
    }
  },
  computed: {
    ...mapGetters({
      assessmentModule: "assessment/assessmentModule",
      assessmentInfo: "assessment/assessmentInfo",
      colorCodes: "global/colorCodes"
    }),
    getModuleSettings() {
      let data = this.assessmentModule;
      if(data.collection_settings && data.collection_settings.collections) {
        return data;
      } else {
        return JSON.parse(data);
      }
    }
  },
  methods: {
    getModuleName() {
      let title = '';
      if(this.assessmentModule) {
        if(this.assessmentModule.collection_settings) {
          if(this.assessmentModule.collection_settings.index_id) {
            title = this.assessmentModule.collection_settings.collections[0].index_id;
          }
          if(this.assessmentModule.collection_settings.index_number) {
            title = title + '' + this.assessmentModule.collection_settings.collections[0].index_number;
          }
          title = title + ' ' + this.assessmentModule.title;
        } else {
          title = this.assessmentModule.title;
        }
      }
      return title;
    },
    getModuleBg() {
      let moduleSettings = null;
      let data = this.assessmentModule;
      moduleSettings = data.collection_settings;
      if(moduleSettings) {
        let settings = moduleSettings.collections[0];
        if (settings) {
          if(settings.custom_bg) {
            return settings.custom_bg;
          }
          let colors = this.getLevelWiseColorCode(1);
          return colors[settings.color];
        }
      }
      return "";
    },
    getScenarioCollection() {
      if(this.assessmentModule && this.assessmentModule.scenario_collections) {
        return this.assessmentModule.scenario_collections;
      }
      return [];
    },
    getLevelWiseColorCode(level) {
      let indexLevel = level - 1;
      return this.colorCodes.slice((indexLevel * 9), ((indexLevel * 9) + 9));
    },
    getBg(item) {
      if (item.background_color) {
        return item.background_color;
      }
      let moduleSettings = this.getModuleSettings.collection_settings;
      if(moduleSettings) {
        let settings = moduleSettings.collections[item.level];
        if (settings) {
          let colors = this.getLevelWiseColorCode(item.level);
          return colors[settings.color];
        }
      }
      return "";
    },
    getTitle(root, parentCodeIndex, index, l1Index = 0, l2Index = 0, l3Index = 0) {
      let nextNumber = index + 1;
      let returnCode = '';
      let moduleSettings = this.assessmentModule.collection_settings;
      if (moduleSettings && moduleSettings.collections) {
        if (moduleSettings.index_id) {
          returnCode = moduleSettings.collections[parentCodeIndex].index_id;
        }
        if (moduleSettings.index_number) {
          if (root) {
            let number = parseInt(moduleSettings.collections[parentCodeIndex].index_number) + parseInt(index);
            returnCode = returnCode + number;
          } else {
            let serial = parseInt(moduleSettings.collections[1].index_number) + parseInt(l1Index);
            if(parentCodeIndex > 2) {
              serial = serial + '.' + (l2Index + 1);
              if(parentCodeIndex > 3) {
                serial = serial + '.' + (l3Index + 1);
              }
            }
            returnCode = serial + '.' + nextNumber;
          }
        }
        return returnCode;
      } else {
        return null;
      }
    },
    toggleExpand(item, root, parentCodeIndex, index, l1Index = 0, l2Index = 0, l3Index = 0) {
      this.activeCollection = item;
      item.indexing_info = this.getTitle(root, parentCodeIndex, index, l1Index, l2Index, l3Index);
      item.is_module = false;
      this.$emit("toggleAssessItem", item);
      this.$emit("moveToFeedback");
    },
    toggleModuleExpand() {
      let moduleSettings = this.assessmentModule.collection_settings;
      this.moduleExpand = !this.moduleExpand;
      let item = this.assessmentModule;
      item.is_module = true;
      item.child = item.scenario_collections;
      if(moduleSettings && moduleSettings.collections) {
        item.indexing_info = moduleSettings.collections[0].index_number;
      } else {
        item.indexing_info = '';
      }
      this.$emit("toggleAssessItem", item);
    },
    moveToCover() {
      this.$emit("moveToCover");
    },
    emptyActive() {
      this.activeCollection = null;
    }
  }
}
</script>

<style scoped>
.assess-title {
  color: #4F7982;
}

.grey {
  color: #787878;
}

.create-button {
  height: 40px;
  background: #232326;
  border-radius: 2px;
  margin: 30px 0;
}

.create-button span {
  color: #656565;
}

.info-div {
  height: 895px;
  margin-bottom: 30px;
  overflow-y: auto;
}

.info-div span, .share-link {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 1rem;
  color: #787878;
}

/*.info-div div {*/
/*    margin-bottom: 30px;*/
/*}*/
.leading-info {
  color: #C4C4C4 !important;
}

.share-link {
  margin-bottom: 20px;
  cursor: pointer;
}

.social img {
  margin-right: 30px;
  cursor: pointer;
}

.assess-padding {
  padding-left: 30px;
  padding-right: 30px;
}

.assess-border {
  border-bottom: 1px solid #28282B;
}

.title-padding {
  padding-bottom: 30px;
}

.assessment-left-items {
  margin-left: 30px;
  margin-right: 27px;
}

.item-py {
  padding-top: 22px;
  padding-bottom: 22px;
}

.assess-ml-l2 {
  margin-left: 30px;
}

.assess-ml-l3 {
  margin-left: 30px;
}

.assess-ml-l4 {
  margin-left: 30px;
}

.ellipse-assess {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipse-assess:hover {
  color: #e7e7e7;
}
.selected-l-data {
  background: #232326;
}
</style>
