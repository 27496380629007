<template>
  <div class="ml-10 mr-20 flex">
    <div class="assess-middle-layer shadow-one bg-primary-three px-10 pb-5 rounded  pt-3">
      <div class="border-b border-secondary-five" style="height: 62px">
        <!-- button will be here by using absolute -->
      </div>
      <div class="assessment-preview-div overflow-y-auto ws-scrollbar pr-8 mt-3" id="scrollDiv">
        <div 
          class="flex flex-col i-border-b-1 border-secondary-five border-solid i-mb-25 i-pb-10 w-full"
          id="assess_content"
          :class="{'i-pb-25': assessmentInfo && assessmentInfo.description}"
        >
          <span class="font-poppins fw-600 text-primary-one w-full" v-if="assessmentInfo">
            <h2 class="fs-18 font-poppins fw-600 text-primary-one placeholder-secondary-two w-full">{{ assessmentInfo.title }}</h2>
          </span>
          <p class="font-poppins fs-14 text-primary-one mt-1">{{ assessmentInfo.description }}</p>
        </div>

        <div class="flex flex-col">
          <!--Level 1-->
          <div class="flex flex-col i-border-b-2 border-primary-one border-solid" v-for="(l1, l1Index) in getScenarioCollection()"
               :key="l1Index" :class="{'i-mt-30': l1Index > 0}" :id="'identifier_group_' + l1Index">
            <div>
              <h3 class="font-poppins fw-600 text-primary-one fs-18">{{ getL1Indexing(l1Index, 1) }} {{ l1.title }}</h3>
              <p class="font-poppins text-primary-one fs-14" :class="{'i-border-b-1 border-solid border-secondary-five i-pb-30': l1.child.length > 0}">{{ l1.description ? l1.description : 'No description available' }}</p>
              
              <div class="i-pb-10 i-mt-25" :class="{'d-none': l1.child.length > 0}">
                <div :class="{'d-none': l1.child.length > 0}" class="space-y-2 pb-4">
                  <div v-for="(property, pIndex) in assessmentModule.assess_properties" :key="pIndex">
                    <div class="flex items-center">
                      <h2 class="w-40 truncate fw-400 font-poppins fs-12 text-primary-one">
                        {{ property.title }}
                      </h2>
                      <img class="mr-6" src="/images/line.png" alt="" />
                      <PreviewProgress :definitionItems="getDefinitionItems" :property="property"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--Level 2-->
            <div class="flex flex-col i-mt-30" v-for="(l2, l2Index) in l1.child" :key="l2Index" :class="{'i-border-b-1 border-solid border-secondary-five': l2.child.length <= 0}">
              <div>
                <h3 class="font-poppins fw-600 text-primary-one fs-18">{{ getSubLevelIndexing(l2Index, getL1Indexing(l1Index, 1), 2) }} {{ l2.title }}</h3>
                <p class="font-poppins text-primary-one fs-14" :class="{'i-border-b-1 border-solid border-secondary-five i-pb-30': l2.child.length > 0}">{{ l2.description ? l2.description : 'No description available' }}</p>
                
                <div class="i-pb-10 i-mt-25" :class="{'d-none': l2.child.length > 0}">
                  
                  <div :class="{'d-none': l2.child.length > 0}" class="space-y-2 pb-4">
                    <div v-for="(property, pIndex) in assessmentModule.assess_properties" :key="pIndex">
                      <div class="flex items-center">
                        <h2 class="w-40 truncate fw-400 font-poppins fs-12 text-primary-one">
                          {{ property.title }}
                        </h2>
                        <img class="mr-6" src="/images/line.png" alt="" />
                        <PreviewProgress 
                          :property="property"
                          :definitionItems="getDefinitionItems"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--Level 3-->
              <div class="flex flex-col i-mt-30" v-for="(l3, l3Index) in l2.child" :key="l3Index" :class="{'i-border-b-1 border-solid border-secondary-five': l3.child.length <= 0}">
                <div>
                  <h3 class="font-poppins fw-600 text-primary-one fs-18">{{ getSubLevelIndexing(l3Index, getSubLevelIndexing(l2Index, getL1Indexing(l1Index, 1), 2), 3) }} {{ l3.title }}</h3>
                  <p class="font-poppins text-primary-one fs-14" :class="{'i-border-b-1 border-solid border-secondary-five i-pb-30': l3.child.length > 0}">{{ l3.description ? l3.description : 'No description available' }}</p>

                  <div class="i-pb-10 i-mt-25" :class="{'d-none': l3.child.length > 0}">
                    
                    <div :class="{'d-none': l3.child.length > 0}" class="space-y-2 pb-4">
                      <div v-for="(property, pIndex) in assessmentModule.assess_properties" :key="pIndex">
                        <div class="flex items-center">
                          <h2 class="w-40 truncate fw-400 font-poppins fs-12 text-primary-one">
                            {{ property.title }}
                          </h2>
                          <img class="mr-6" src="/images/line.png" alt="" />
                          <PreviewProgress :definitionItems="getDefinitionItems" :property="property"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!--Level 4-->
                <div class="flex flex-col i-mt-30 i-border-b-1 border-solid border-secondary-five" v-for="(l4, l4Index) in l3.child" :key="l4Index">
                  <div>
                    <h3 class="font-poppins fw-600 text-primary-one fs-18">{{ getSubLevelIndexing(l4Index, getSubLevelIndexing(l3Index, getSubLevelIndexing(l2Index, getL1Indexing(l1Index, 1), 2), 3), 4) }} {{ l4.title }}</h3>
                    <p class="font-poppins text-primary-one fs-14">{{ l4.description ? l4.description : 'No description available' }}</p>
                    
                    <div class="i-pb-10 i-mt-25" :class="{'d-none': l4.child.length > 0}">

                      <div :class="{'d-none': l4.child.length > 0}" class="space-y-2 pb-4">
                        <div v-for="(property, pIndex) in assessmentModule.assess_properties" :key="pIndex">
                          <div class="flex items-center">
                            <h2 class="w-40 truncate fw-400 font-poppins fs-12 text-primary-one">
                              {{ property.title }}
                            </h2>
                            <img class="mr-6" src="/images/line.png" alt="" />
                            <PreviewProgress :definitionItems="getDefinitionItems" :property="property"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="assess-last-layer ml-10 rounded assess-root-box">
      <div class="assess-last-layer-headings flex justify-between bg-primary-three shadow-one rounded">
        <h2 class="fs-14 fw-700 font-poppins text-secondary-one opacity-60 cursor-pointer" @click="selections = 'ideas'" :class="{'opacity-100': selections === 'ideas'}">Ideas</h2>
        <h2 class="fs-14 fw-700 font-poppins text-secondary-one opacity-60 cursor-pointer" @click="selections = 'comments'" :class="{'opacity-100': selections === 'comments'}">Comments</h2>
      </div>
      <div class="flex flex-col items-center justify-center assess-right-h">
        <img src="/images/icons/assessment/none.svg" alt="image" class="no-items-img text-center">
        <h2 class="fs-20 font-roboto grey c41 mt-2 ml-2">No {{ selections === 'ideas' ? 'ideas' : 'comments' }} found</h2>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import PreviewProgress from '@/components/assessment/PreviewProgress.vue'
import $ from "jquery";

export default {
  name: "Preview",
  props: ['assessItem', 'childids'],
  components: {
    PreviewProgress
  },
  data() {
    return {
      selections: 'ideas',
    }
  },
  computed: {
    ...mapGetters({
      assessmentInfo: "assessment/assessmentInfo",
      assessmentModule: "assessment/assessmentModule",
      definitionSets: 'definition/definitionSets'
    }),
    getDefinitions() {
      return this.definitionSets.filter(defSet => defSet.items.find(definition => definition.type === 1));
    },
    getDefinitionItems() {
      if(this.assessmentInfo.definition_id) {
        let definition = this.getDefinitions.find(defSet => parseInt(defSet.id) === parseInt(this.assessmentInfo.definition_id));
        if(definition) {
          return definition.items.find(item => item.type === 1).range_with_definition;
        }
      }
      return [];
    }
  },
  methods: {
    getScenarioCollection() {
      if(this.assessmentModule && this.assessmentModule.scenario_collections) {
        return this.assessmentModule.scenario_collections;
      }
      return [];
    },
    getL1Indexing(index, selfLevel) {
      let settings = this.assessmentModule.collection_settings;
      if(settings) {
        let collection = settings.collections[selfLevel];
        return parseFloat(collection.index_number) + index;
      }
      return '';
    },
    getSubLevelIndexing(index, currentData, selfLevel) {
      return currentData + '.' + (index + 1);
    },
    scrollToIdentifier(id) {
      let index = this.getScenarioCollection().findIndex(item => parseInt(item.id) === parseInt(id));
      if(index > 0) {
        let scrolledPos = 0;
        this.getScenarioCollection().forEach((item, iIndex) => {
          if(iIndex < index) {
            scrolledPos += $("#identifier_group_" + iIndex).height();
          }
        });
        $("#scrollDiv").animate({
          // scrollTop: scrolledPos + $("#assess_content").height() + (30 + (index + 1)) + (25 * (index + 1))
          scrollTop: scrolledPos 
        }, 800);
      } else {
        $("#scrollDiv").animate({
          scrollTop: 0
        }, 800);
      }
    },

    getPropertyValue(itemId, propertyId) {
      let value = 0;
      let item = this.childids.find(item => parseInt(item.id) === parseInt(itemId));
      if(item && item.property_collections) {
        let propertyValue = item.property_collections.find(prop => parseInt(prop.id) === parseInt(propertyId));
        if(propertyValue) {
          value = propertyValue.value;
        }
      }
      return value * 10;
    },
    changePropertyValue(e, scenario_collection_id, property_collection_id) {
      let id = document.getElementById('progress-track-' + scenario_collection_id + '-' + property_collection_id)
      let value = parseFloat(e.target.value)
      let trackWidth = value * 10 + '%'
      id.style.width = trackWidth
    },
  }
}
</script>

<style scoped>
.track-box {
  width: 100%;
}
.progress-track {
  height: 2px;
}
.range {
  height: 2px;
  width: 100%;
  outline: none;
  -webkit-appearance: none;
}
input[type='range']::-webkit-slider-thumb {
  width: 30px;
  -webkit-appearance: none;
  height: 15px !important;
  cursor: pointer;
  background: #00A7FE;
  border-radius: 2px;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
}
</style>
