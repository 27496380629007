export default function useDefinition() {
    const getDefinitionTitleByRange = (definitionArray, range) => {
        let definition  = definitionArray.find(definition => 
            range >= limitFromLimitString(definition.range, 'lower') 
            && range <= limitFromLimitString(definition.range)
        )

       return definition.definition ? definition.definition : 'No definition'
    }

    //return upper or lower limit based on index
    function limitFromLimitString(text, type) {
        let strArray = text.split('-')
        if(type == 'lower') {
            return parseInt(strArray[0])
        }
        return parseInt(strArray[1])
    }

    return {
        getDefinitionTitleByRange
    }
}