<template>
  <div style="height: 1080px" class="_main_wrapper flex">
    <left-part class="i-left-panel shadow-one"
      :showFeedback="showFeedback"
      @toggleAssessItem="toggleAssessItem"
      @moveToCover="moveToCover"
    />
    <div class="i-right-panel">
      <div class="ws-heading-box relative flex flex-col pb-0">
        <workspace-menu />
      </div>

      <right-part
        :current="current"
        :assessItem="assessItem"
        :childids="childids"
        @toggleCurrentTab="toggleCurrentTab"
        @pushChildIds="pushChildItem"
        ref="assess_right_box"
      >
        <div class="assess-menu-box absolute -mt-8 left-20">
          <div class="flex gap-10">
            <button @click="toggleToConfigure()" :class="[current === 'configure' ? 'opacity-50': 'opacity-100']">
              <div class="flex items-center gap-2">
                <div class="h-5 w-5 rounded-full  border border-secondary-five fs-10 flex items-center justify-center">1</div>
                <span class="text-primary-one">Configure</span>
              </div>
            </button>
            <!-- <button
                v-if="current === 'configure'"
                @click="updateAssessment()"
                class="h-10 px-6 py-2 focus:outline-none hover:bg-secondary-five text-primary-one fs-14 font-poppins fw-600 bg-primary-three shadow-one rounded flex items-center justify-center"
                :class="{'bg-primary-four text-secondary-three': current === 'configure'}">
              Save Changes
            </button> -->
            <button
              @click="toggleToPreview()"
              :class="[current === 'preview' ? 'opacity-50': 'opacity-100']"
            >
              <div class="flex items-center gap-2">
                <div class="h-5 w-5 rounded-full  border border-secondary-five fs-10 flex items-center justify-center">2</div>
                Preview
              </div>
            </button>
            <button
                @click="toggleToSend()"
                :class="[current === 'send' ? 'opacity-50': 'opacity-100']"
              >
                <div class="flex items-center gap-2">
                  <div
                    class="h-5 w-5 rounded-full  border border-secondary-five fs-10 flex items-center justify-center">3</div>
                  <span class="text-primary-one">Send</span>
                </div>
            </button>

            <!-- <button
                @click="toggleToSend()"
                v-if="current !== 'send'"
                class="h-10 px-6 py-2 ml-5 focus:outline-none hover:bg-secondary-five text-primary-one fs-14 font-poppins fw-600 bg-primary-three shadow-one rounded flex items-center justify-center"
                :class="{'bg-primary-four text-secondary-three': current === 'send'}">
              Send
            </button> -->
            <!-- <button
                @click="addSender()"
                v-if="current === 'send'"
                class="h-10 px-6 py-2 ml-5 focus:outline-none hover:bg-secondary-five text-primary-one fs-14 font-poppins fw-600 bg-primary-three shadow-one rounded flex items-center justify-center"
                :class="{'bg-primary-four text-secondary-three': current === 'send'}">
              Save Changes
            </button> -->
          </div>
        </div>

      </right-part>
    </div>
  </div>
</template>

<script>
import LeftPart from "@/components/assessment/survey/LeftPart";
import {mapGetters} from "vuex";
import RightPart from "@/components/assessment/Edit/RightPart";

export default {
  name: "EditAssessment",
  components: {
    RightPart,
    LeftPart
  },

  created() {
    document.title = 'Edit Assessment';
    const self = this;
    self.$store.dispatch("definition/getDefinitionSets", { project_id: self.$route.params.id });
    self.$store.dispatch("assessment/setWorkingAssessment", null);
    self.$Progress.start();
    self.$store.dispatch("programmatic/loadScenarios", self.$route.params.id);
    self.$store.dispatch("programmatic/getModules", self.$route.params.id)
        .then(response => {
          if (response) {
            self.$store.dispatch("manageProperty/loadProperties", self.modules[0].id)
          }
        });
    self.$store.dispatch("workspace/projectSelection", self.$route.params.id);
    this.$store.dispatch("project/getSolidObject", this.$route.params.id);
    self.$store.dispatch("assessment/getScenarioCollection", {
      assessment_id: self.$route.params.assessment_id
    }).then(response => {
      self.$Progress.finish();
      if(response && response.data && response.data.data) {
        self.getChildIds();
      }
    });
  },
  data() {
    return {
      showFeedback: false,
      assessItem: null,
      current: 'configure',
      childids: [],
    }
  },
  computed: {
    ...mapGetters({
      assessmentModule: "assessment/assessmentModule",
      modules: "programmatic/projectModules",
      assessmentInfo: "assessment/assessmentInfo",
      definitionSets: 'definition/definitionSets'
    }),
  },
  watch: {
    assessmentInfo: function(val) {
      if(val) {
        this.assessItem = val;
      }
    }
  },
  methods: {
    getScenarioCollection() {
      if(this.assessmentModule && this.assessmentModule.scenario_collections) {
        return this.assessmentModule.scenario_collections.data;
      }
      return [];
    },
    toggleAssessItem(item) {
      this.assessItem = item;
    },
    moveToCover() {
      this.showFeedback = false;
    },
    toggleToConfigure() {
      this.current = 'configure';
    },
    toggleToPreview() {
      this.current = 'preview';
    },
    toggleToSend() {
      this.current = 'send';
    },
    updateAssessment() {
      this.$refs.assess_right_box.updateAssessment();
    },

    toggleCurrentTab(value) {
      this.current = value;
    },
    getChildIds() {
      const self = this;
      let properties = JSON.parse(this.assessmentInfo.property_collections);
      let propValues = [];
      properties.forEach(prop => {
        propValues.push({ id: prop, value: 3 });
      });
      self.getScenarioCollection().forEach(item => {
        let obj = {
          id: item.id,
          property_collections: propValues
        };
        self.childids.push(obj);
        if(item.child.data.length > 0) {
          self.pushChildIds(item, propValues);
        }
      });
    },
    pushChildIds(data, propValues) {
      const self = this;
      data.child.data.forEach(item => {
        let obj = {
          id: item.id,
          property_collections: propValues
        };
        self.childids.push(obj);
        if(item.child.data.length > 0) {
          self.pushChildIds(item, propValues);
        }
      });
    },
    pushChildItem(obj) {
      let index = this.childids.findIndex(item => parseInt(item.id) === parseInt(obj.id));
      if(index >= 0) {
        this.childids.splice(index, 1);
      }
      this.childids.unshift(obj);
    }
  }
}
</script>

<style scoped>

</style>
