<template>
  <div class="ml-10 mr-20">
    <div class="shadow-one bg-primary-three px-10 pb-5 rounded assess-root-box" v-if="assessmentInfo">
      <div class="border-b border-secondary-five" style="height: 74px">
        <!-- button will be here by using absolute -->
      </div>
      <div class="flex items-center i-mt-25 gap-6">
        <input
          style="width: 620px;"
          class="title-field bg-transparent fs-16 h-10 font-poppins fw-600 text-primary-one placeholder-secondary-two border border-secondary-five rounded px-4"
          placeholder="Assessment title"
          type="text"
          v-model="assessmentInfo.title"
        >
        <!-- <slot name="edit-btn"/> -->
      </div>
      <div class="flex items-center gap-3 i-mt-25">
        <textarea
          style="width: 620px"
          class="bg-transparent font-poppins fs-14 text-primary-one placeholder-secondary-two bld-content-area border border-secondary-five p-3 h-20 rounded"
          placeholder="Add assessment description here"
          v-model="assessmentInfo.description"
        ></textarea>
        <span class="text-secondary-two fs-14 fw-400">Note: Assessment will always be controlled <br> and distributed with sub level control.</span>
      </div>

      <div class="i-mt-25">
        <div class="flex mb-2 items-center">
          <h2 class="font-poppins text-primary-one fs-14"><span class="mr-3">1.</span>Select Scenario:</h2>
          <select class="fs-14 font-poppins ml-4 text-primary-one bg-transparent pr-select-dropdown fw-600" @change="changeActiveScenario($event)" v-model="assessmentInfo.scenario_id" disabled>
            <option :value="scenario.id" v-for="(scenario, index) in scenarios" :key="index">{{ scenario.title }}</option>
          </select>
        </div>

        <div class="ml-6 grey flex items-center">
          <h2 class="font-poppins text-primary-one fs-14">Choose the Module you want to Assess:</h2>
          <select class="fs-14 font-poppins ml-4 text-primary-one bg-transparent pr-select-dropdown fw-600" @change="changeActiveModule($event)" v-model="assessmentInfo.module_id" disabled>
            <option :value="module.id" v-for="(module, index) in modules" :key="index">{{ module.title }}</option>
          </select>
        </div>
      </div>

      <div class="i-mt-25">
        <h2 class="font-poppins text-primary-one fs-14 mb-3">
          2. Which Parameters do you want to assess?
        </h2>
        <div class="flex mb-2 items-center">
          <h2 class="font-poppins text-primary-one fs-14 ml-3">Setup Assessment Values:</h2>
          <select
            class="fs-14 font-poppins ml-4 text-primary-one bg-transparent pr-select-dropdown fw-600"
            @change="changeActiveSet($event)"
            v-model="assessmentInfo.property_id"
            disabled
          >
            <option :value="set.id" v-for="(set, sindex) in propsets" :key="sindex">{{ set.title }}</option>
          </select>
        </div>
        <div class="flex">
          <div class="border border-secondary-five rounded">
            <div
              class="flex items-center cursor-pointer border-b border-secondary-five pl-6 pt-3 pb-3 pr-16 property"
              v-for="(property, pindex) in getPropertyCollection"
              :key="pindex" disabled
            >
              <div
                class="ws-checkbox bg-transparent w-3 h-3 i-border-1 border-secondary-one i-mr-10"
                :class="{'bg-primary-one': checkedPropSelected(property.id)}"
              ></div>
              <span class="fs-14 font-poppins text-primary-one opacity-75" :class="{'opacity-100': checkedPropSelected(property.id)}">{{ property.title }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="i-mt-25">
        <div class="flex mb-2 items-center gap-5">
          <h2 class="font-poppins text-primary-one fs-14"><span class="mr-3">3.</span>Select definition Set:</h2>
          <select
            class="fs-14 font-poppins ml-4 text-primary-one bg-transparent pr-select-dropdown fw-600"
            v-model="assessmentInfo.definition_id"
            placeholder="Select definition">
            <option v-for="(definition, dIndex) in getDefinitions" :value="definition.id" :key="dIndex">{{ definition.title }}</option>
          </select>
          <h2 class="text-primary-one fs-14 font-poppins">(These definitions will be used if  a property does not have an associated property set.) </h2>
        </div>
        <div class="flex ml-6 gap-8  mb-2 pt-4">
          <h2 class="fw-400 font-poppins fs-14 text-primary-one text-center def-col"
              v-for="(definitionItem, dIndex) in getDefinitionItems"
              :key="dIndex">{{ definitionItem.definition }}
          </h2>
        </div>
      </div>

      <DefinitionColorPicker
        class="ml-6"
        :definition_styles="assessmentInfo.definition_style"
      />
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import DefinitionColorPicker from '@/components/assessment/DefinitionColorPicker.vue'

export default {
  name: "Configure",
  components: {
    DefinitionColorPicker
  },
  props: ['assessItem'],
  data() {
    return {
      checkedProps: []
    }
  },
  computed: {
    ...mapGetters({
      assessmentInfo: "assessment/assessmentInfo",
      scenarios: "programmatic/scenarios",
      modules: "programmatic/projectModules",
      propsets: "manageProperty/properties",
      definitionSets: 'definition/definitionSets'
    }),
    getPropertyCollection() {
      let property = this.propsets.find(item => parseInt(item.id) === parseInt(this.assessmentInfo.property_id));
      if(property) {
        this.setCheckedProps();
        return property.property_collections;
      }
      return [];
    },
    getDefinitions() {
      return this.definitionSets.filter(defSet => defSet.items.find(definition => definition.type === 1));
    },
    getDefinitionItems() {
      if(this.assessmentInfo.definition_id) {
        let definition = this.getDefinitions.find(defSet => parseInt(defSet.id) === parseInt(this.assessmentInfo.definition_id));
        // return definition.items.find(item => item.type === 1).range_with_definition;
      }
      return [];
    }
  },
  methods: {
    setCheckedProps() {
      this.checkedProps = JSON.parse(this.assessmentInfo.property_collections);
    },
    changeActiveScenario(e) {
      this.scenario = e.target.value;
    },
    changeActiveModule(e) {
      this.module = e.target.value
      this.$store.dispatch("manageProperty/loadProperties", this.module)
          .then(response => {
            if(response) {
              this.propset = this.propsets[0].id
              this.properties = this.propsets[0].property_collections
            }
          });
    },
    changeActiveSet(e) {
      this.propset = e.target.value
      this.properties = this.propsets.find(el => el.id == this.propset).property_collections
      this.checkedProps = []
    },
    togglePropertySelections(id) {
      const self = this;
      const index = this.checkedProps.indexOf(id);
      if (index > -1) {
        this.checkedProps.splice(index, 1);
      } else {
        this.checkedProps.push(id);
      }

      let data = [];
      this.checkedProps.forEach(itemId => {
        let item = self.properties.find(item => parseInt(item.id) === parseInt(itemId));
        if(item) {
          data.push(item);
        }
      });
      this.$store.dispatch("assessment/setPropsChecked", data);
    },
    selectProperty(id) {
      this.checkedProps.push(id)
    },
    unselectProperty(id) {
      const index = this.checkedProps.indexOf(id);
      if (index > -1) {
        this.checkedProps.splice(index, 1);
      }
    },
    checkedPropSelected(id) {
      let status = this.checkedProps.indexOf(id)
      if (status != -1) {
        return true
      }
      return false
    },
    update() {
      this.$Progress.start();
      let obj = {}
      obj.id = this.assessmentInfo.id;
      obj.title = this.assessmentInfo.title !== "" ? this.assessmentInfo.title : "New Assessment";
      obj.description = this.assessmentInfo.description;
      obj.definition_id = this.assessmentInfo.definition_id;
      obj.definition_style = this.assessmentInfo.definition_style;
      this.$store.dispatch("assessment/updateAssessment", obj).then(response => {
        this.$Progress.finish();
        this.$emit("toggleCurrentTab", 'preview');
      })
    },
  }
}
</script>

<style scoped>
select:disabled {
  cursor: not-allowed;
}
.track-box {
  width: 100%;
}
.value-track {
  height: 2px;
}
.progress-track {
  height: 2px;
  background: #00A7FF;
}
.range {
  height: 2px;
  outline: none;
  -webkit-appearance: none;
}
.prop-disabled[type='range']::-webkit-slider-thumb {
  cursor: not-allowed !important;
  display: none;
}
input[type='range']::-webkit-slider-thumb {
  width: 30px;
  -webkit-appearance: none;
  height: 15px !important;
  cursor: pointer;
  background: #00A7FE;
  border-radius: 2px;
  box-shadow: 0 6px 15px rgba(35, 39, 47, 0.13);
}
.slider-control-width {
  width: 800px;
}
.def-col {
  width: 184px;
}
</style>
