<template>
    <div v-click-outside="hidePicker" class="flex i-gap-x-1-9 relative">
       <div
            style="width: 185px"
            @click="currentStyle = style, showPicker= !showPicker"
            v-for="(style, index) in definition_styles"
            :key="index"
            class="i-h-30 flex justify-center items-center text-white fs-12 rounded-sm cursor-pointer"
            :style="{background: style.bg}"
        >
            {{style.range}}
       </div> 
       <div v-if="showPicker" class="flex flex-col items-center justify-center mt-3 absolute picker-position">
            <color-panel v-model="currentStyle.bg" ></color-panel>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DefinitionColorPicker',
    data: () => ({
        showPicker: false,
        currentStyle: {}
    }),
    props: {
        definition_styles: Array
    },
    methods: {
        hidePicker() {
            if(this.showPicker) {
                this.showPicker = false
            }
        }
    }
}
</script>

<style scoped>
.picker-position {
    left: 259px;
    top: -260px
}
</style>